<template>
  <div>
    <!-- <b-row class="content-header" v-if="this.customGmbValue">
      <b-col class="content-header-left mb-2" cols="12" md="9">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              <b-avatar
                class="mr-1"
                size="32"
                :src="client.logo"
                :text="avatarText(client.name)"
                :variant="client.logo ? `white` : `light-primary`"
              />
              {{ client.name }}
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in breadcrumbs"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row> -->
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-card-title>
            <div class="header-container">
              <div>
                <h4 class="app-title mdsm">
                  {{ this.clientName }} has {{ this.count }} stores across the
                  India.
                </h4>
              </div>
              <div class="right-header-container">
                <h6 v-if="this.lastSyncDate !== ''">Last Synced at {{ this.lastSyncDate }}</h6>
                <b-button @click="syncCallForGMB" :disabled="this.isSyncing" variant="outline-success"><span
                    style="font-size: 14px;">Sync</span><feather-icon icon="RefreshCwIcon" size="14"
                    style="margin-left: 8px;" /> <b-spinner style="margin-left: 8px;" v-if="this.isSyncing"
                    small></b-spinner></b-button>
              </div>

            </div>
          </b-card-title>
        </b-col>
        <div class="select-box-container">
          <b-col cols="3">
            <div class="form-group">
              <label for="clientSelect" class="form-label">Client:</label>
              <div class="select-container">
                <b-form-select id="clientSelect" v-model="selectedSlug" @change="getAllData(true, false)">
                  <option v-for="onetype in fetchClientsList" :value="onetype.slug" class="form-control" id="lg_type"
                    name="lg_type" v-bind:key="onetype.slug">
                    {{ onetype.name }}
                  </option>
                </b-form-select>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="loctionSelect" class="form-label">Location Groups:</label>
              <div class="select-container">
                <b-form-select id="loctionSelect" v-model="selectedLocationGroup" @change="getAllData(true, true)">
                  <option v-for="onetype in allgmbAccounts" :value="onetype.name.substring(9)" class="form-control"
                    id="lg_type" name="lg_type" v-bind:key="onetype.name.substring(9)">
                    {{ onetype.accountName }}
                  </option>
                </b-form-select>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="monthSelect" class="form-label"> Month:</label>
              <div class="select-container">
                <b-form-select id="monthSelect" v-model="selectedMonth" @change="getAllData(true, true)"
                  class="custom-select">
                  <option v-for="month in months" :disabled="isMonthDisabled(month)" :value="month.value"
                    :key="month.value">
                    {{ month.label }}
                  </option>
                </b-form-select>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label for="yearSelect" class="form-label"> Year:</label>
              <div class="select-container">
                <b-form-select id="yearSelect" v-model="selectedYear" @change="getYearChangeData">
                  <option v-for="year in years" :value="year.label" :key="year.value">
                    {{ year.label }}
                  </option>
                </b-form-select>
              </div>
            </div>
          </b-col>
        </div>
      </b-row>
    </b-card>
    <!-- <b-row class="content-header" v-if="!this.customGmbValue">
      <b-col class="content-header-left mb-2" cols="12" md="10">
        <b-row class="breadcrumbs-top">
          <b-col cols="10">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Google My Business
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row> -->
    <div>
      <b-card>
        <div class="schedule-hearder">
          <div>
            <b-card-title>
              <h3 class="app-title mdsm">Summary Insights from GMB</h3>
            </b-card-title>
          </div>
          <div>
            <input type="text" class="search-field" v-model="searchSummaryInshightsTerm"
              placeholder="Search by columns name." />
            <b-button style="margin-left: 10px; margin-bottom: 4px;" @click="exportToExcel('summary')"
              class="exportButton" variant="primary"><span style="font-size: 12px;">Export</span>
              <feather-icon icon="DownloadIcon" size="15" style="margin-left: 5px;" />
            </b-button>
          </div>
        </div>
        <div v-if="isLoadingSummaryData" class="spinner">
          <b-spinner small></b-spinner>
        </div>
        <vue-good-table class="custom-data-table" ref="goodTableSummary" :rows="SummarytableData"
          :columns="SummarytableColumns" :search-options="{
            enabled: true,
            externalQuery: searchSummaryInshightsTerm,
          }" :pagination-options="{
  enabled: true,
  perPage: summmaryPageLength,
}">
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'percentageChange'">
              <span class="spanContainer">
                <p v-if="!props.row.percentageChange">0</p>
                <p v-else>{{ Math.abs(props.row.percentageChange) }} %</p>

                <feather-icon class="up-icon" v-if="props.row.percentageChange > 0" icon="ArrowUpIcon" size="18" />
                <feather-icon v-if="props.row.percentageChange < 0" icon="ArrowDownIcon" size="18" class="down-icon" />
              </span>
            </div>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1"></div>
              <div>
                <b-pagination :value="1" :total-rows="props.total" :per-page="summmaryPageLength" first-number last-number
                  align="right" prev-class="prev-item" next-class="next-item" v-model="paginatedDataNumber"
                  class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </div>
    <div>
      <b-card>
        <div class="schedule-hearder">
          <div>
            <b-card-title>
              <h3 class="app-title mdsm">Organic vs Paid</h3>
            </b-card-title>
          </div>
          <div>
            <b-button @click="exportToExcel('organic')" variant="primary"><span
                style="font-size: 12px;">Export</span><feather-icon icon="DownloadIcon" size="13"
                style="margin-left: 5px;" /></b-button>
          </div>
        </div>
        <div v-if="OrganicVsPaidAdsSpinner" class="spinner">
          <b-spinner small></b-spinner>
        </div>
        <vue-good-table ref="goodTableOrganic" class="custom-data-table" :rows="organicVsPaidData"
          :columns="OrganicVsPaidTable" :pagination-options="{
            enabled: true,
            perPage: organicVsPaidLenghth,
          }">
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'delta'" class="table-align">
              <span class="spanContainer">
                <p v-if="!props.row.delta">0</p>
                <p v-else>{{ Math.abs(props.row.delta) }} %</p>
                <feather-icon class="up-icon" v-if="props.row.delta > 0" icon="ArrowUpIcon" size="18" />
                <feather-icon v-if="props.row.delta < 0" icon="ArrowDownIcon" size="18" class="down-icon" />
              </span>
            </div>
            <div v-else-if="props.column.field == 'value'" class="table-align">
              <span class="spanContainer">
                <p v-if="!props.row.delta">0</p>
                <p v-else>{{ props.row.value }}</p>
              </span>
            </div>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1"></div>
              <div>
                <b-pagination :value="1" :total-rows="props.total" :per-page="organicVsPaidLenghth" first-number
                  last-number align="right" prev-class="prev-item" next-class="next-item" v-model="paginatedDataNumber"
                  class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </div>
    <div>
      <b-card>
        <div class="schedule-hearder">
          <div>
            <b-card-title>
              <h3 class="app-title mdsm">Paid Ads Effectiveness</h3>
            </b-card-title>
          </div>
          <div>
            <b-button @click="exportToExcel('paid')" variant="primary"><span
                style="font-size: 12px;">Export</span><feather-icon icon="DownloadIcon" size="13"
                style="margin-left: 5px;" /></b-button>
          </div>
        </div>
        <div v-if="paidAdsEffectivenessSpinner" class="spinner">
          <b-spinner small></b-spinner>
        </div>
        <vue-good-table class="custom-data-table" ref="goodTablePaid" :rows="effectivenessData"
          :columns="EffectivenessTable" :pagination-options="{
            enabled: true,
            perPage: paidAdsEffectiveness,
          }">
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'delta'" class="table-align">
              <span class="spanContainer">
                <p v-if="!props.row.delta">0</p>
                <p v-else>{{ Math.abs(props.row.delta) }} % </p>
                <feather-icon class="up-icon" v-if="props.row.delta > 0" icon="ArrowUpIcon" size="18"
                  :style="{ color: props.row.color }" />
                <feather-icon v-if="props.row.delta < 0" icon="ArrowDownIcon" size="18" class="down-icon"
                  :style="{ color: props.row.color }" />
              </span>
            </div>
            <div v-else-if="props.column.field == 'value'" class="table-align">
              <span class="spanContainer">
                <p v-if="!props.row.value">0</p>
                <p v-else>{{ props.row.value }}</p>
              </span>
            </div>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1"></div>
              <div>
                <b-pagination :value="1" :total-rows="props.total" :per-page="paidAdsEffectiveness" first-number
                  last-number align="right" prev-class="prev-item" next-class="next-item" v-model="paginatedDataNumber"
                  class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </div>
    <div>
      <b-card>
        <div class="schedule-hearder">
          <div>
            <b-card-title>
              <h3 class="app-title mdsm">Store wise Ads Insights</h3>
            </b-card-title>
          </div>
          <div>
            <input type="text" class="search-field" v-model="searchStoreWiseDataTerm"
              placeholder="Search by columns name." />
            <b-button style="margin-left: 10px; margin-bottom: 4px;" @click="exportStorewiseData" class="exportButton"
              variant="primary"><span style="font-size: 12px;">Export</span><feather-icon icon="DownloadIcon" size="15"
                style="margin-left: 5px;" /></b-button>
          </div>
        </div>
        <div v-if="isLoadingStoreWiseData" class="spinner">
          <b-spinner small></b-spinner>
        </div>
        <vue-good-table class="custom-data-table" ref="goodTableIndividual" :rows="storeWiseData"
          :columns="storeWiseDataColumns" :search-options="{
            enabled: true,
            externalQuery: searchStoreWiseDataTerm,
          }" :pagination-options="{
  enabled: true,
  perPage: pageLength,
}">
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1"></div>
              <div>
                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                  align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                  v-model="paginatedStoreWiseData" @input="(value) => props.pageChanged({ currentPage: value })">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </div>
    <div>
      <b-card>
        <div class="schedule-hearder">
          <div>
            <b-card-title>
              <h3 class="app-title mdsm">Individual store data</h3>
            </b-card-title>
          </div>
          <div>
            <input class="search-field" type="text" v-model="searchTerm" placeholder="Search by columns name." />
            <b-button style="margin-left: 10px; margin-bottom: 4px;" @click="exportIndividiualStoreData"
              class="exportButton" variant="primary"><span style="font-size: 12px;">Export</span><feather-icon
                icon="DownloadIcon" size="15" style="margin-left: 5px;" /></b-button>
          </div>
        </div>
        <div v-if="isLoadingIndividualData" class="spinner">
          <b-spinner small></b-spinner>
        </div>
        <vue-good-table class="custom-data-table" ref="goodTableIndividual" :rows="AllSummarytableData"
          :columns="IndividualDatatableColumns" :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }" :pagination-options="{
  enabled: true,
  perPage: pageLength,
}">
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.label == 'Store Address'">
              <span v-b-tooltip.hover class="tooltip-trigger overflow-text" :title="props.row.address">
                {{ props.row.address }}
              </span>
            </div>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1"></div>
              <div>
                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                  align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                  v-model="paginatedDataNumber2" @input="(value) => props.pageChanged({ currentPage: value })">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BLink,
  BCardBody,
  BFormSelect,
  BCardTitle,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  BAvatar,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { sortFuntionNumericVal, sortFuntionStringintoNumericVal } from '@/components/client/SortingFunction';
export default {
  name: "gmb",
  components: {
    BSpinner,
    BCol,
    BRow,
    BCard,
    BButton,
    vSelect,
    BCardBody,
    DateRangePicker,
    BFormSelect,
    VueGoodTable,
    BCardTitle,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    BAvatar,
  },
  props: {
    customGmbValue: {
      type: String,
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    },
  },
  data() {
    return {
      selectedYear: "2024",
      years: [{ label: "2023", value: 0 }, { label: "2024", value: 1 }],
      months: [
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 },
      ],
      customIntialValue: true,
      selectedMonth: new Date().getMonth(),
      allgmbAccounts: [],
      organicVsPaidData: [],
      effectivenessData: [],
      // selectedYear: 0,
      isSyncing: false,
      searchTerm: "",
      searchSummaryInshightsTerm: "",
      searchStoreWiseDataTerm: "",
      count: 0,
      AllSummarytableData: [],
      SummarytableData: [],
      fetchClientsList: [],
      pageLength: 5,
      summmaryPageLength: 10,
      organicVsPaidLenghth: 5,
      effectivenessDataLength: 5,
      paginatedDataNumber: 1,
      paginatedDataNumber2: 1,
      paginatedStoreWiseData: 1,
      paidAdsEffectiveness: 5,
      selectedClientId: "",
      selectedSlug: "",
      clientName: "",
      selectedLocationGroup: "",
      currentYear: new Date().getFullYear(),
      id: "",
      lastSyncDate: "",
      client: {
        status: false,
        integrations: {
          googleAds: null,
        },
        googleAdsCustomerID: null,
      },
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      selected: {
        label: "Last 7 days",
        code: "LAST_7_DAYS",
      },
      clients: [],
      selected: null,
      opens: true,
      isLoadingSummaryData: false,
      paidAdsEffectivenessSpinner: false,
      OrganicVsPaidAdsSpinner: false,
      isLoadingIndividualData: false,
      isLoadingStoreWiseData: false,
      storeWiseData: [],
      dateRange: {
        endDate: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    getYearChangeData(){
      if (this.selectedYear == new Date().getFullYear()) {
        this.selectedMonth = new Date().getMonth();
      }
      this.getAllData(true, true);
    },
    sortFn(a, b) {
      return sortFuntionNumericVal(a, b);
    },
    sortStringtoNumFn(a, b) {
      return sortFuntionStringintoNumericVal(a, b);
    },
    exportStorewiseData() {
      const csvContent =
        "data:text/csv;charset=utf-8," +
        this.convertStoreWiseData(this.storeWiseData);
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Storewise-Ads-Insights-data.csv");
      document.body.appendChild(link);
      link.click();
    },
    convertStoreWiseData(data) {
      const headers = this.storeWiseDataColumns.map((column) => column.label);
      let rows = data.map((row) =>
        this.storeWiseDataColumns.map((column) => {
          const value = this.extractValue(row, column.field);
          if (column.field === "Driving directions") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Address") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Call clicks") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Driving directions") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Local reach (impressions)") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Location address line 1") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Location address line 2") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Location business name") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Location city") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Location country code") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Location postcode") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Location province") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Month") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Shop visits") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "Website visits") {
            return value.replace(/,/g, "`");
          }
          return value;
        })
      );
      const csvContent = [headers.join(","), ...rows].join("\n");
      return csvContent;
    },
    exportIndividiualStoreData() {
      const csvContent =
        "data:text/csv;charset=utf-8," +
        this.convertIndividualStoreData(this.AllSummarytableData);
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Individual_store_data.csv");
      document.body.appendChild(link);
      link.click();
    },
    convertIndividualStoreData(data) {
      const headers = this.IndividualDatatableColumns.map(
        (column) => column.label
      );
      const rows = data.map((row) =>
        this.IndividualDatatableColumns.map((column) => {
          const value = this.extractValue(row, column.field);
          if (column.field === "address") {
            return value.replace(/,/g, "`");
          }
          if (column.field === "name") {
            return value.replace(/,/g, "`");
          }
          return value;
        })
      );
      const csvContent = [headers.join(","), ...rows].join("\n");
      return csvContent;
    },
    extractValue(obj, field) {
      const fieldSegments = field.split(".");
      let value = obj;
      for (const segment of fieldSegments) {
        value = value[segment];
        if (value === undefined) {
          return "";
        }
      }
      return value;
    },
    exportToExcel(value) {
      let rows = [];
      let headerRow;
      if (value === "summary") {
        rows = this.SummarytableData;
        headerRow = this.$refs.goodTableSummary.columns.map(
          (column) => column.label
        );
      }
      if (value === "organic") {
        rows = this.organicVsPaidData;
        headerRow = this.$refs.goodTableOrganic.columns.map(
          (column) => column.label
        );
      }
      if (value === "paid") {
        rows = this.effectivenessData;
        headerRow = this.$refs.goodTablePaid.columns.map(
          (column) => column.label
        );
      }
      const csvContent = [
        headerRow.join(","),
        ...rows.map((row) => this.formatRowData(row, value)),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv" });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "exported_data.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    extractValue(obj, field) {
      const fieldSegments = field.split(".");
      let value = obj;
      for (const segment of fieldSegments) {
        value = value[segment];
        if (value === undefined) {
          return "";
        }
      }
      return value;
    },
    formatRowData(row, value) {
      if (value === "summary") {
        return this.$refs.goodTableSummary.columns
          .map((column) => {
            const value = row[column.field];
            return typeof value === "string" ? `"${value}"` : value;
          })
          .join(",");
      }
      if (value === "organic") {
        return this.$refs.goodTableOrganic.columns
          .map((column) => {
            const value = row[column.field];
            return typeof value === "string" ? `"${value}"` : value;
          })
          .join(",");
      }
      if (value === "paid") {
        return this.$refs.goodTablePaid.columns
          .map((column) => {
            const value = row[column.field];
            return typeof value === "string" ? `"${value}"` : value;
          })
          .join(",");
      }
    },
    isMonthDisabled(month) {
      if (this.selectedYear == "2024") {
        const currentMonth = new Date().getMonth();
      const monthIndex = this.months.indexOf(month);
      return monthIndex >= currentMonth + 1;
      }
    },
    setIntialClient() {
      if (this.customIntialValue) {
        this.selectedSlug = this.$route.params.slug;
        if (!this.$route.params.slug) {
          this.selectedSlug = this.fetchClientsList[0].slug;
        }
        this.getAllData(true, false);
        this.customIntialValue = false;
      }
    },
    fetchGMBCustomers() {
      if (!this.selectedClientId) {
        return;
      }
      const slug = this.selectedSlug;
      const getGmbAccount = `${process.env.VUE_APP_SERVER_URL}/api/gmb/accounts`;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = { ...response.data.client, status: true };

          return axios.post(
            getGmbAccount,
            {
              clientID: this.selectedClientId,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          );
        })
        .then((response) => {
          this.allgmbAccounts = response.data.accounts;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.client.status = false;
          this.errorMessage = error.response.data.error;
        });
    },
    syncCallForGMB() {
      this.isSyncing = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/sync`,
          {
            clientID: this.selectedClientId,
            month: this.selectedMonth + 1,
            year:parseInt(this.selectedYear, 10)
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.isSyncing = false;
          this.showToast("Success", "Sync process may take upto few minutes!", "success");
        })
        .catch((error) => {
          this.isSyncing = false;
          console.log(error.response.data);
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    fetchLastSyced() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/lastSynced?month=${this.selectedMonth + 1}&year=${this.selectedYear}&accountID=${this.selectedLocationGroup}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.lastSyncDate = response.data.formattedlastSyncedAt;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchStoreWiseData(clientID) {
      this.storeWiseData = [];
      this.isLoadingStoreWiseData = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL
          }/api/gmb/stores/fetch/perStoreData?month=${this.selectedMonth +
          1}&clientID=${clientID}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.storeWiseData = response.data;
          this.isLoadingStoreWiseData = false;
        })
        .catch((error) => {
          this.isLoadingStoreWiseData = false;
          console.log(error);
        });
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    getAllData(slugChanged = false, locationGroupChanged = false) {
      this.isLoadingSummaryData = true;
      this.isLoadingIndividualData = true;
      this.isLoadingStoreWiseData = true;
      this.paidAdsEffectivenessSpinner = true;
      this.OrganicVsPaidAdsSpinner = true;
      this.effectivenessData = []
      this.organicVsPaidData  = []
      let slug = "";
      if (!slugChanged) {
        slug = this.$route.params.slug;
      } else {
        slug = this.selectedSlug;
      }

      const insightsData = `${process.env.VUE_APP_SERVER_URL}/api/gmb/summary/insights`;
      const getAllInsightsData = `${process.env.VUE_APP_SERVER_URL}/api/gmb/insights/all`;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = { ...response.data.client, status: true };
          this.selectedClientId = this.client._id;
          this.selectedSlug = this.client.slug;
          this.clientName = this.client.name;
          if (!locationGroupChanged) {
            this.selectedLocationGroup = this.client.gmb.accountID;
            if (Array.isArray(this.client.gmb.accountID)) {
              this.selectedLocationGroup = this.selectedLocationGroup[0];
            }
          }
          this.fetchClients();
          this.fetchGMBCustomers();
          this.fetchStoreWiseData(this.selectedClientId);
          this.fetchLastSyced();
          return axios.post(
            insightsData,
            {
              startDate: this.dateRange.startDate,
              endDate: this.dateRange.endDate,
              clientID: this.selectedClientId,
              accountID: this.selectedLocationGroup,
              month: this.selectedMonth,
              year:parseInt(this.selectedYear, 10)
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          );
        })
        .then((response) => {
          this.paginatedDataNumber = 1;
          this.SummarytableData = response.data;
          this.isLoadingSummaryData = false;
          return axios.post(
            getAllInsightsData,
            {
              startDate: this.dateRange.startDate,
              endDate: this.dateRange.endDate,
              clientID: this.selectedClientId,
              accountID: this.selectedLocationGroup,
              month: this.selectedMonth,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          );
        })
        .then((response) => {
          this.count = response.data.length;
          this.AllSummarytableData = response.data;
          this.isLoadingIndividualData = false;
          this.isLoadingStoreWiseData = false;
          this.paginatedDataNumber2 = 1;
          return axios.post(
            `${process.env.VUE_APP_SERVER_URL}/api/gmb/visitors`,
            {
              clientID: this.selectedClientId,
              month: this.selectedMonth,
              year: this.selectedYear,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
                "Content-type": "application/json",
              },
            }
          );
        })
        .then((response) => {
          this.paidAdsEffectivenessSpinner = false;
          this.OrganicVsPaidAdsSpinner = false;
          this.organicVsPaidData = response.data.organicVsPaid;
          this.effectivenessData = response.data.paidAdsEffectiveness.slice(0, 3);
        })
        .catch((error) => {
          console.log(error.response.data);
          this.paidAdsEffectivenessSpinner = false;
          this.OrganicVsPaidAdsSpinner = false;
          this.client.status = false;
          this.errorMessage = error.response.data.error;
          this.showToast("Error", error.response.data.error, "danger");
        });
    },
    fetchClients() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/get`,
          {
            limit: 50,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.fetchClientsList = response.data.clients.filter((client) => {
            return client.gmb && client.gmb.accountID.length;
          });

          this.setIntialClient();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // getClientData() {
    //   const slug = this.$route.params.slug;
    //   axios
    //     .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
    //       headers: {
    //         Authorization: `Bearer ${getUserToken()}`,
    //       },
    //     })
    //     .then((response) => {
    //       this.client = { ...response.data.client, status: true };
    //       this.breadcrumbs.push({
    //         text: response.data.client.name,
    //         to: `/client/${response.data.client.slug}`,
    //         active: false,
    //       });
    //       this.selectedClientId = this.client._id
    //       this.selectedLocationGroup = this.client.gmb.accountID;
    //       this.breadcrumbs.push({
    //         text: "Google My Business",
    //         to: `/client/${response.data.client.slug}/dashboard/google-ads`,
    //         active: true,
    //       });
    //     }
    //     )
    //     .catch((error) => {
    //       console.log(error.response.data);
    //       this.client.status = false;
    //       this.errorMessage = error.response.data.error;
    //     });
    // },
    dateChanged(val) {
      this.$emit("fetchNewData", val.code);
    },

    runAllFunc() {
      this.getAllData(false, false);
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    updateValues(values) {
      let startDate = moment(values.startDate).format("DD-MM-YYYY");
      let endDate = moment(values.endDate).format("DD-MM-YYYY");
      this.$emit("fetchNewData", startDate, endDate);
      this.getAllData(false, false);
    },
    dateFormat(classes, date) {
      let yesterday = new Date();
      let d1 = moment(date).format("DD-MM-YYYY");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "DD-MM-YYYY"
      );
      if (!classes.disabled) {
        classes.disabled = d1 === d2;
      }
      return classes;
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  computed: {
    SummarytableColumns() {
      const summaryLabel = `${this.months[this.selectedMonth].label} 2023`;
      return [
        {
          label: "Metric",
          field: "title",
          sortable: false
        },
        {
          label: summaryLabel,
          field: "value",
          sortFn: this.sortFn
        },
        {
          label: "Change over previous month",
          field: "percentageChange",
          sortFn: this.sortFn,
        },
      ];
    },
    OrganicVsPaidTable() {
      const summaryLabel = `${this.months[this.selectedMonth].label} 2023`;
      return [
        {
          label: "Metric",
          field: "title",
          sortable: false
        },
        {
          label: summaryLabel,
          field: "value",
          sortFn: this.sortFn
        },
        {
          label: "Change over previous month",
          field: "delta",
          sortFn: this.sortFn
        },
      ];
    },
    EffectivenessTable() {
      const summaryLabel = `${this.months[this.selectedMonth].label} 2023`;
      return [
        {
          label: "Metric",
          field: "title",
          sortable: false
        },
        {
          label: summaryLabel,
          field: "value",
          sortFn: this.sortStringtoNumFn,
          sortable: false
        },
        {
          label: "Change over previous month",
          field: "delta",
          sortFn: this.sortFn
        },
      ];
    },
    IndividualDatatableColumns() {
      return [
        {
          label: "Store code",
          field: "storeCode",
          filterable: true,
          thClass: "text-center",
        },
        {
          label: "Business Name",
          field: "name",
          filterable: true,
          thClass: "text-center",
          class: "custom-name-column",
          sortable: false
        },
        {
          label: "Store Address",
          field: "address",
          filterable: true,
          thClass: "text-center",
          class: "overflow-text",
          sortable: false
        },
        {
          label: "Overall rating",
          field: "insight.overallRating",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Total searches",
          field: "insight.totalSearches",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Direct searches",
          field: "insight.directSearches",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Discovery searches",
          field: "insight.discoverySearches",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortStringtoNumFn
        },
        {
          label: "Total views",
          field: "insight.totalViews",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Search views",
          field: "insight.searchViews",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Maps views",
          field: "insight.mapViews",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Total actions",
          field: "insight.totalActions",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Website actions",
          field: "insight.websiteActions",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Directions actions",
          field: "insight.directionActions",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn
        },
        {
          label: "Phone call actions",
          field: "insight.callActions",
          filterable: true,
          thClass: "text-center",
          sortFn: this.sortFn
        },
      ];
    },
    storeWiseDataColumns() {
      return [
        {
          label: "Location business name",
          field: "Location business name",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Address",
          field: "Address",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Location phone number",
          field: "Location phone number",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Local reach (impressions)",
          field: "Local reach (impressions)",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Call clicks",
          field: "Call clicks",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Driving directions",
          field: "Driving directions",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Website visits",
          field: "Website visits",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Shop visits",
          field: "Shop visits ",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ];
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
      };
      return ranges;
    },
  },
  mounted() {
    // document.getElementById("custom-Stores").classList.add("active");
    if (this.customIntialValue) {
      this.fetchClients();
    } else {
      this.getAllData(false, false);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.header-container {
  display: flex;
  justify-content: space-between;
}

.comparison-dashboard-link {
  font-size: 16px;
  font-weight: 500;
  color: #200e6b;
}

.vue-daterange-picker {
  width: 100%;

  svg {
    color: #4d21ff;
  }
}

.reportrange-text {
  display: flex;
  align-items: center;
}

.date-range {
  font-size: 11px !important;
}

.picker-controller {
  background-color: #f5f6fa;
}

.date-range-picker-container {
  background-color: #f5f6fa;
  padding: 8px;
  width: 240px;
}

.main-gmb-container {
  padding: 0 13%;
}

.schedule-hearder {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

input {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 205px;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}

.spinner {
  justify-content: space-around;
  display: flex;
  left: auto;
  right: auto;
  margin-bottom: 10px;
}

.up-icon {
  margin-left: 10px;
  color: green;
  align-items: center;
}

.down-icon {
  margin-left: 10px;
  color: red;
  align-items: center;
}

.collapse-icon {
  color: grey;
  float: right;
  cursor: pointer;
}

.custom-name-column {
  display: block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.overflow-text {
  display: block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.spanContainer {
  width: 100px;
  display: flex;
}

.custom-select option:disabled {
  background-color: #efefef;
  color: #b8c2cc;
}

.form-group {
  display: block !important;
}

.form-label {
  font-size: 12px !important;
}

.tooltip-trigger {
  position: relative;
  cursor: pointer;
}

.select-box-container {
  width: 100%;
  display: flex;
}

.right-header-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.right-header-container>h6 {
  margin-top: 5px;
  font-size: 11px;
  color: #999999;
}

@media only screen and (max-width: 767px) {
  .select-box-container {
    display: grid;
    grid-template-columns: auto;
  }

  .schedule-hearder {
    display: grid;
    grid-template-columns: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .custom-select {
    padding: 0.438rem 2rem 0.438rem 1rem !important;
    width: 24rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .exportButton {
    margin-top: 20px;
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .header-container,
  .right-header-container {
    display: grid;
    grid-template-columns: auto;
    text-align: center;
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .search-field {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 395px) and (min-height: 568px) and (max-height: 860px) {
  .custom-select {
    padding: 0.438rem 2rem 0.438rem 1rem !important;
    width: 22rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
</style>
